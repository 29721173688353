#containerTitle{
  display: flex;
  /* margin-bottom: -30px; */
    width: 90%;
  margin-left: 10%;
    background-color: rgba(128, 128, 128, 0.5);
    height: 30px;
   /*  margin-bottom: -10px; */
    
}

#TitleGraph{
 justify-content: center;
}

#ContainerButtonsTitle{
  position: relative;
  display: flex;
  justify-content: flex-end;
}


#ButtonFilterImage{
  background-image: url(../img/icono-calendario.png);
  background-size: cover;
  border: none;
  height: 40px;
  margin: 12px;
  width: 40px;
  background-color: transparent;
}

#ButtonConfImage{
  background-color: transparent;
  background-image: url(../img/conf_icon.png);
  background-size: cover;
  border: none;
  margin: 12px;
  height: 40px;
  width: 40px;
}
 #confImage{
  width: 28px;
  border: 1px solid gray;
 
}

#calImage{
  width: 28px;
  border: 1px solid gray;
  margin-left: 7px;
}

#_graph{
  left: 50%;
  margin: 5px auto 35px;
  border: solid #000;
  border-radius: 5px;
}

#home_div{
  height: 100%;
}

.modal-title{
text-align: center;
}

.Selectable{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: max-content;
}
.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #087e8b;
}
.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}
.Selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.Selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

#ButtonOkCalendar{
  display: block;
  position: static;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--color-green);
  border-color: var(--color-green);
  width: 50%;
}

#ModalGraph{
  position:relative;
  height: 450px;
}


#selector-date-id{
  text-align: center;
}

#ContainerTitleGraph{
  color: var(--color-5);
}

#TitleGraph{
  left: 2%;
  margin-top: 15px;
  position: relative;
}

ContainerButtonsTitle{
  text-decoration: underline;
  cursor: pointer;
}

#GraphRow{
  height: 350px;
  margin-bottom: 10px;
}

#saveThresholdButton{
  background-color: var(--color-green);
  border-color: var(--color-green);
}

#cancelThresholdButton{
  background-color: var(--color-red);
  border-color: var(--color-red);
}

#ButtonResetDate{
  background: none!important;
  border: none;
  padding: 0!important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}
