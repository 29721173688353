#miImage{
    /* -webkit-filter:invert(100%);
    filter:invert(100%); */
    filter:invert(100%);
}
.desple{
    background-color:#343a40;
    color:white;
    padding-left:5px ;
    margin-left: 0px;
    
    /*margin: 0px;
    padding: 0px;
    border: 3px solid red !important;*/
}
/*#miList .desple{
    border-bottom: 0.5px solid white;
}*/
.conte{
    margin: -9px 0 -8px;

    /*border: 2px solid red !important;*/
}
.desple i{
    /*margin-left: -6px;*/
    margin-right: 0.4em;
    /*border: 2px solid red !important;*/
}


@media screen and (min-width:320px) and (max-width:479px){
    .desple{
        font-size: 1.5em;
       /* border: 2px solid red;*/
    }
}

#logo-vit{
    cursor: pointer;
}
