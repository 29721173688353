#todo{
   background-image: url(../img/fondo4.jpeg);
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
    position: fixed;
 /*  min-width: 100vw;*/
    width: 100%;
   height: 100%;

}
.tarjeta_sensor{
  /*  float:left;*/
  /*  display:inline;*/
    width: 260px;
    margin: 50px;
    height: 300px;
 /*   flex-wrap: wrap;*/
    z-index: 1 !important;
    border: 3px solid white;
}
#tarjetasSensores{
    display: flex;
    flex-wrap: wrap;
}

#home_div{
    height: auto;
    width: 100%;
}
.CropType{
    position: absolute;
    margin-top: 10px;
    margin-left: 10px;
    color: white;
}

.TitleCardContainer{
    display: flex;
    justify-content: space-between;
}
.editIcon{
    position: relative;
    width: 22px;
    height: 22px;  
    margin-top: -15px;
  
}
.linkEditIcon:hover{
    filter: opacity(0.2);
    transition: .5s;
}
.StateSensor{
    position: absolute;
    top: 0px;
    right: 0px;
 /*   margin-top: -158px;*/
}

#colorStateInactive{
    color: brown;
}

#colorStateActive{
    color: #329F69;
}
#sensor_card:hover{
cursor: pointer;
filter: opacity(0.9);
transition: .5s;
}
.editIcon{
    position: relative;
    width: 22px;
    height: 22px;
    margin-top: -15px;
    float: right;

}
.linkEditIcon:hover{
    filter: opacity(0.2);
    transition: .5s;
}

@media only screen and (max-width: 700px) {
    #todo{
        overflow: scroll;
    }
}

/*---------------------------------------*/
.LoadGIF{
    margin-top: 10%;
    margin-left: 47%;
    width: 130px;
}

.LoadText{
    color: white;
    
    margin-left: 37%;
}


