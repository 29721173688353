#errorContainer{
    margin-top: 21%;
    margin-bottom: auto;
}

#errorResetLink{
    background: none!important;
    border: none;
    padding: 0!important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #069;
    text-decoration: underline;
    cursor: pointer;
}