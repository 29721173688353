*{
    font-family:'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen';
  }
  .ContainerLoginRegister {
   
    background-image: url(../img/7581.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    /*width: 100vw;*/
    /*height: 100vh;*/
    /* background-color: rgba(26, 45, 61, 0.8) !important; */
   padding: 2% 4% ;
  }
  
  
  .CardContainer{
    
   
    display: flex;
    flex-wrap: wrap;
    border-radius: 15px;
  }
  
  .LoginContainer{
    /* width: 70%; */
    padding: 50px;
    
    justify-content: center;
    /*position: relative;*/
    background-color: rgba(255, 255, 255, 0.9);
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }
  /*.LogoVIT{
    margin-left: 38%;
   
  }*/
  .TitleLogIn{
    margin: 0;
    margin-top: 40px;
    text-align: center;
   
  }
  .FormLogIn{
    margin-top: 40px;
    /*margin-left: 30%;*/
    
  }
  .InputEmail,.InputPass{
  height:50px !important ;
  font-size: 20px !important;
  }
  .InputPass{
  margin-top: 25px;
  }
  
  #contra{
    position: relative;	
   }
   #contra i{
    position: absolute;
    top: 92px;
    right: 20px;		
   }
  .FormLogIn h5{
  margin-top: 35px;
  font-size: 15px;
  text-decoration: underline;
  text-align: center;
  }
  .btn-block{
    margin-top: 40px;
    height:50px !important ;
    font-size: 20px !important;
    background-color: #136caf !important;
  }
  
  .btn-block:hover{
    background-color: #0f4f80 !important;
  }

  /* ---------------------- */
  .SignUpContainer{
      background-color: #136caf;
      text-align: center;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
  }
  .SignUpContainer h5{
        
       
    /*padding: 20px 30px;*/
    color: white;
   
  }
  
  .TextAside{
  
  /*margin-top: 50%;*/
  color: white;
  }
  .LogoVIT{
    display: block;
    margin-left: auto;
    margin-right: auto;
    
  } 
  @media screen and (min-width:1024px) {
    .LoginContainer{
      width: 70%;
    }
    .SignUpContainer{
      
        width: 30%;
    }
    
    .FormLogIn{
      width: 310px !important;
      /*width: 39%;*/
      margin-left: 30%;
    }
    .LogoVIT{
      margin-left: 38%;
     
    }
    .TextAside{
        margin-top: 50%;
    }
    .SignUpContainer h5{
        
       
      padding: 20px 30px;
    }
    
      
  }
  @media screen and (min-width:768px) and (max-width:1023px){
    .LoginContainer{
      width: 68%;
    }
    .SignUpContainer{
      
        width: 32%;
    }
    .FormLogIn{
      /*width: 310px !important;*/
      width: 80%;
      margin-left: 15%;
    }
    
    .TextAside{
        margin-top: 50%;
        font-size: 1.9em;
    }
    
  }
  @media screen and (min-width:320px) and (max-width:767px){
    
    
    /*.LoginContainer{
      border-top-right-radius: 15px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
     
      
    }
    .SignUpContainer{
      
      border-bottom-left-radius: 15px;
      border-top-right-radius: 0px;
      
      margin-bottom: 45px;
      padding:30px 5% 20px 5%;
      
    }*/
    .LoginContainer{
      border-top-left-radius: 0px;
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;
     
      
    }
    .SignUpContainer{
      
      border-top-left-radius: 15px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0;
      margin-bottom: 45px;
      padding:30px 5% 20px 5%;
      
    }
    
    .CardContainer{
      
      flex-wrap: wrap-reverse;
    }
    
  }
  @media screen and (min-width:480px) and (max-width:767px){
    .LoginContainer,.SignUpContainer{
      margin: 0 auto;
      width: 90%;
    }
    
  }
 
  
  @media screen and (min-width:320px) and (max-width:479px){
    .LoginContainer,.SignUpContainer{
      margin: 0 auto;
      width: 95%;
    }
    
    .LoginContainer{
      padding-left: 8px;
      padding-right: 8px;
    }
    
  }

  
  
  
  